import * as React from 'react';
import { useFetchApi } from '../utils/UseFetchApi';
import { HoursTimespan } from './HoursTimespanSelector';
import { InvalidJobsFromPools } from 'realityservices-ui-components';

export default function InvalidJobsFromBatch() {
    const [clusters, setClusters] = React.useState<any[]>([]);
    const getClusters = useFetchApi(window.location.origin + "/api/v1/clusters");

    React.useEffect(() => {
        getClusters.run().then((resp: any) => {
            setClusters(resp);
        })
    }, []);

    const timeOptions = [
        { value: HoursTimespan.LastHour, label: 'Last Hour' },
        { value: HoursTimespan.Last6Hours, label: 'Last 6 Hours' },
        { value: HoursTimespan.Last24Hours, label: 'Last 24 Hours' },
        { value: HoursTimespan.LastWeek, label: 'Last Week' },
        { value: HoursTimespan.Last2Weeks, label: 'Last 2 Weeks' },
        { value: HoursTimespan.LastMonth, label: 'Last Month' },
        { value: HoursTimespan.LastQuarter, label: 'Last Quarter' },
        { value: HoursTimespan.Last2Quarters, label: 'Last 2 Quarters' }
    ];
    const locationOptions = [
        { value: "EastUs", label: 'East US' },
        { value: "CentralIndia", label: 'Central India' },
        { value: "AustraliaEast", label: 'Australia East' },
        { value: "NorthEurope", label: 'North Europe' },
        { value: "SoutheastAsia", label: 'Southeast Asia' },
        { value: "UKSouth", label: 'UK South' },
        { value: "JapanEast", label: 'Japan East' }
    ];

    function handleDelete(job: any) {
        const jobLocation = job.dataCenter ? job.dataCenter.location.toString() : job.location;
        return `${window.location.origin}/api/v1/clusters/jobId/${job.id.toString()}/locations/${jobLocation}/terminate`;
    }

    return (
      <div>
        <InvalidJobsFromPools
          getInvalidJobReportUrl={window.location.origin + "/api/v1/clusters/invalidJobs"}
          getCancelJobUrl={handleDelete}
          showFilters={true}
          title={"Invalid Jobs From Pools"}
          showTimeFilter={true}
          showLocationFilter={true}
          timeFilterOptions={timeOptions}
          locationFilterOptions={locationOptions}
          defaultTimeFilterOption={{
            value: HoursTimespan.LastWeek,
            label: "Last Week"
          }}
          defaultlocationFilterOptions={{ value: "EastUs", label: "East US" }}
        />
      </div>
    );
}
