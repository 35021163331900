import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
//:>--------------------------------------------------------------------------------------+
//:>
//:>  $Source: InvalidJobsFromPools.tsx $
//:>
//:>  $Copyright: (c) 2017 Bentley Systems, Incorporated. All rights reserved. $
//:>
//:>+--------------------------------------------------------------------------------------
import * as React from 'react';
import MaterialTable from "@material-table/core";
import { useFetchApi } from '../utils/UseFetchApi';
import { useSnackbar } from 'notistack';
import { Grid, Button, Table, TableCell } from '@mui/material';
import FilterList from '@mui/icons-material/FilterList';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { createDateColumn } from '../utils/DateUtils';
import { Selector } from "./Selector";
import moment from 'moment';
export function InvalidJobsFromPools(props) {
    const invalidJobReport = useFetchApi(props.getInvalidJobReportUrl);
    const fetchApi = useFetchApi();
    const [isJobDeleted, setJobDeleted] = React.useState(false);
    const [expanded, setExpanded] = React.useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const [timeSpan, setTimeSpan] = React.useState(props.defaultTimeFilterOption.value);
    const [location, setLocation] = React.useState(props.defaultlocationFilterOptions.value);
    React.useEffect(() => {
        invalidJobReport.run();
    }, [timeSpan, location]);
    React.useEffect(() => {
        if (isJobDeleted) {
            invalidJobReport.run();
            setJobDeleted(false);
        }
    }, [isJobDeleted]);
    function getHoursDifference(jobStartTime) {
        var todaysTimeInMiliseconds = moment.utc().valueOf();
        var startTimeInMiliseconds = moment.utc(jobStartTime).valueOf();
        const timeDifference = Math.abs(todaysTimeInMiliseconds - startTimeInMiliseconds);
        const dayDifference = timeDifference / (1000 * 60 * 60 * 24);
        return dayDifference * 24;
    }
    function getData() {
        var jobs = [];
        const invalidJobs = invalidJobReport.data;
        if (invalidJobs && invalidJobs.length > 0 && props.showFilters) {
            const filteredJobs = [];
            const timeFilteredJob = [];
            invalidJobs.forEach((job) => {
                if (props.showTimeFilter) {
                    var hoursDifference = getHoursDifference(job.executionInfo.startTime);
                    if (hoursDifference <= timeSpan) {
                        if (props.showLocationFilter) {
                            if (location.toString().toLowerCase() == job.location.toString().toLowerCase()) {
                                if (filteredJobs.findIndex((j) => j.id == job.id) == -1)
                                    filteredJobs.push(job);
                            }
                        }
                        else {
                            if (filteredJobs.findIndex((j) => j.id == job.id) == -1)
                                filteredJobs.push(job);
                        }
                    }
                }
                if (props.showLocationFilter && !props.showTimeFilter) {
                    if (location.toString().toLowerCase() == job.location.toString().toLowerCase()) {
                        if (timeFilteredJob.findIndex((j) => j.id == job.id) == -1)
                            filteredJobs.push(job);
                    }
                }
            });
            return filteredJobs;
        }
        return jobs;
    }
    function handleCancel(rowData) {
        const data = invalidJobReport.data;
        let job;
        if (data) {
            job = data.find((j) => j.id === rowData.id);
        }
        const url = job ? props.getCancelJobUrl(job) : props.getCancelJobUrl(rowData);
        fetchApi.run(url, {
            method: 'POST'
        }).then((resp) => {
            enqueueSnackbar(`Job ${rowData.id} deleted from pool.`, { variant: "success" });
            setJobDeleted(true);
        });
    }
    function onTimespanChanged(newTimespan) {
        setTimeSpan(newTimespan);
    }
    function onLocationChanged(location) {
        setLocation(location);
    }
    function filters() {
        return (_jsxs(Grid, { container: true, spacing: 1, style: { width: 250 }, children: [_jsx(Grid, { item: true, xs: 12, children: _jsx(Selector, { default: props.defaultTimeFilterOption, label: "Active since", onValueChanged: onTimespanChanged, options: props.timeFilterOptions }) }), _jsx(Grid, { item: true, xs: 5, children: _jsx(Selector, { default: props.defaultlocationFilterOptions, label: "Location", onValueChanged: onLocationChanged, options: props.locationFilterOptions }) })] }));
    }
    function filterBar() {
        if (expanded) {
            return (_jsxs(Grid, { container: true, spacing: 1, style: { width: 150 }, children: [_jsx(Grid, { item: true, xs: 1, children: _jsx(Button, { color: "primary", onClick: (event) => setExpanded(false), children: _jsx(ChevronLeftIcon, { fontSize: "large" }) }) }), filters()] }));
        }
        else {
            return (_jsx(Grid, { container: true, spacing: 0, style: { width: 20 }, children: _jsx(Grid, { item: true, xs: 12, children: _jsx(Button, { color: "primary", onClick: (event) => setExpanded(true), children: _jsx(FilterList, { fontSize: "large" }) }) }) }));
        }
    }
    return (_jsx("div", { children: _jsxs(Table, { size: "small", children: [_jsx(TableCell, { children: props.showFilters ? filterBar() : _jsx(_Fragment, {}) }), _jsx(TableCell, { children: _jsx(MaterialTable, { title: props.title, isLoading: invalidJobReport.isFetching, columns: [
                            { title: "Location", field: "location" },
                            { title: "Pool name", field: "executionInfo.poolId" },
                            { title: "Id", field: "id" },
                            { title: "Name", field: "name" },
                            { title: "State", field: "dbState" },
                            createDateColumn("Creation time", "creationTime"),
                            createDateColumn("Start time", "executionInfo.startTime")
                        ], data: getData(), actions: [
                            rowData => ({
                                tooltip: 'Cancel job',
                                icon: 'cancel',
                                onClick: (event, rowData) => { handleCancel(rowData); }
                            })
                        ], options: {
                            actionsColumnIndex: -1,
                            headerStyle: { backgroundColor: '#9BA5AE', color: '#000000' },
                            search: true,
                            paging: true,
                            rowStyle: () => {
                                return {
                                    padding: '50px'
                                };
                            }
                        } }) })] }) }));
}
